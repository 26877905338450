import api from './axios';

export default {
    createSession(){
        return api.get('sanctum/csrf-cookie');
    },
    login(params){
        return api.post('api/login', params);
    },
    logout(){
        return api.delete('api/logout');
    },
    getCars(){
        return api.get('api/cars', {
            headers: {
                'Authorization': localStorage.token ? 'Bearer ' + localStorage.token : null
            }
        });
    },
    getCar(id){
        return api.get(`api/car/${id}`, {
            headers: {
                'Authorization': localStorage.token ? 'Bearer ' + localStorage.token : null
            }
        });
    },
    addCar(carDetails){
        return api.post('api/car/store', carDetails, {
            headers: {
                'Authorization': localStorage.token ? 'Bearer ' + localStorage.token : null
            }
        });
    },
    deleteCar(id){
        return api.delete(`api/car/${id}`, {
            headers: {
                'Authorization': localStorage.token ? 'Bearer ' + localStorage.token : null
            }
        });
    },
    updateCar(id, data){
        return api.put(`api/car/${id}`, data, {
            headers: {
                'Authorization': localStorage.token ? 'Bearer ' + localStorage.token : null
            }
        });
    }
}