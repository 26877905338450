<template>
  <div class="home">
   
     <CarGrid :CarData="carList"/>
  </div>
</template>

<script>
// @ is an alias to /src
import CarGrid from '@/components/CarGrid.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    CarGrid
  },
  async mounted(){
    try{
      await this.$store.dispatch('getCarData');
    }catch(error){
      console.log(error);
    }
  },
  computed:{
    ...mapGetters({
      carList: 'carData'
    })
  }
}
</script>
<style>
.home{
  padding-top: 50px;
}
</style>
