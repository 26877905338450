<template>
    <div class="modal_backdrop">

        <div class="modal_update">
            
            <div class="modal_update_container">

                <div @click="closeUpdateModal" class="modal_update_close">
                    X
                </div>

                <div class="modal_update_inside">

                        <div class="form_wrapper">
       
                            <div class="container">

                                <div class="container_header">
                                    Edycja pojazdu

                                    <hr class="container_header_hr" />
                                </div>
 
                                    <div class="form">
                                        <input class="input_form" type="text" v-model="Car.CarMake" placeholder="Marka"/>
                                        <input class="input_form" type="text" v-model="Car.Model" placeholder="Model"/>
                                        <input class="input_form" type="text" v-model="Car.Year" placeholder="Rok produkcji"/>
                                        <input class="input_form" type="text" v-model="Car.CarPlate" placeholder="Nr Rejestracyjny"/>
                                        <input class="input_form" type="text" v-model="Car.CarReviewDateTo" placeholder="Przegląd do"/>
                                        <input class="input_form" type="text" v-model="Car.InsuranceTo" placeholder="Ubezpieczenie do"/>
                                        <input class="input_form" type="text" v-model="Car.OilChangeDate" placeholder="Data ostatniej wymiany oleju"/>
                                        <input class="input_form last_input" type="text" v-model="Car.Milage" placeholder="Przebieg"/>
                        
                                    </div>

                                    <div v-if="error" class="error_message">
                                            <p>{{error}}</p>
                                        </div>

                                    <div class="container_buttons">
                                        <hr class="container_header_hr" />
                                        <button @click="closeUpdateModal" class="button_send">Anuluj</button>
                                        <button @click="UpdateCar" class="button_send green">Zaktualizuj</button>
                                    </div>

                            </div>

                        </div>
        
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import repository from '@/api/repository';

export default {
    name: 'UpdateModal',
    props:{
        CarData: []
    },
    data(){
        return{
            Car:{
                CarID: this.CarData.id,
                CarMake: this.CarData.CarMake,
                Model: this.CarData.Model,
                Year: this.CarData.Year,
                CarPlate: this.CarData.CarPlate,
                CarReviewDateTo: this.CarData.CarReviewDateTo,
                InsuranceTo: this.CarData.InsuranceTo,
                OilChangeDate: this.CarData.OilChangeDate,
                Milage: this.CarData.Milage
            },
            error: ''
        }
    },  
    methods:{
        async UpdateCar(){
           
            if(this.error === ''){
                try{

                    await repository.updateCar(this.Car.CarID, {"car": this.Car});
                    window.location.reload();
                   
                }catch(e){
                    let err = Object.values(e.response.data.errors)[0];                 
                    this.error = err[0];
                }
            }
        },
        closeUpdateModal(){
            this.$emit('closeModal');
        },
        updateElement(){
            this.$emit('updateCar');
        }
  }
}
</script>

<style scoped>
.container_header{
    text-align: left;
    font-size: 16px;
    font-weight: 500;
}
.container_header_hr{
    margin-top: 10px;
}
.form{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 25px;
}
.input_form{
    width: calc(90% - 20px);
    max-width: 400px;
    height: 25px;
    border: none;
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 20px;
    background: rgba(255,255,255,.8);
    margin: 10px;
}
.input_form:focus{
    outline: none;
    border: none;
}
.container_buttons{
    margin-top: 40px;
}
.button_send{
    height: 35px;
    width: 120px;
    border-radius: 7px;
    border: none;
    background:rgb(231, 231, 231);
    font-weight: 600;
    margin: 20px 5px auto 5px;
    cursor: pointer;
}
.green{
    background: rgb(69, 84, 87);
    color: white;
}
.container{
    margin-top: 15px;
    padding-top: 0;
}


.form_wrapper{
    max-height: 90vh;
    overflow: auto;
}
.modal_backdrop{
    height: 100vh;
    width: 100vw;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
}
.modal_update{
    width: 90%;
    max-width: 1000px;
    background: #363636;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 0px 1px 6px #383838;
}
.modal_update_container{
    width: 100%;
    height: 100%;
    position: relative
}
.modal_update_buttons{
    width: 90%;
    margin: auto;
}
.update_button{
    width: 115px;
    height: 35px;
    border-radius: 5px;
    border: none;
    margin: 5px;
    cursor: pointer;
}
.red{
    background: rgb(88, 19, 19);
    color: white;
    box-shadow: 0 0 4px black;
}
.modal_update_header{
    width: 100%;
    color: white;
    padding: 0px 2px 10px 2px;
}
.modal_update_inside{
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    padding: 5px 0 5px 0;
}
.modal_update_close{
    position: absolute;
    top: -20px;
    right: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #9e9e9e;
    line-height: 40px;
    cursor: pointer;
    color: black;
    font-weight: 700;
}
.update_button:hover, .modal_update_close:hover{
    transform: scale(1.05);
    transition: .4s;
}
</style>