import Vue from 'vue'
import Vuex from 'vuex'
import repository from '../api/repository';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.user ? JSON.parse(localStorage.getItem('user')) : null,
    token: localStorage.token ? localStorage.getItem('token') : null,
    loader: false,
    carList: localStorage.carData ? localStorage.getItem('carData') : [],
  },
  mutations: {
    SET_USER(state, user){
      state.user = user.user ? user.user : null;
      state.token = user.token ? user.token : null;
    },
    SET_LOGOUT(state){
      state.user = null;
      state.token = null;
    },
    SET_LOADER(state, boolean){
      state.loader = boolean;
    },
    SET_CAR_LIST(state, data){
      state.carList = data;
    },
  },
  actions: {
    async login({ commit }, user) {
      await repository.createSession();
      const {data} = await repository.login(user);
      commit('SET_USER', data);

      localStorage.user = JSON.stringify(data.user);
      localStorage.token = data.token;
    },
    async logout({ commit }) {
      await repository.logout();
    
      commit('SET_LOGOUT');

      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
    async showLoader({ commit }) {
      commit('SET_LOADER', true);
    },
    async hideLoader({ commit }) {
      commit('SET_LOADER', false);
    },
    async getCarData({ commit }) {
      await repository.getCars().then((response) =>{
        commit('SET_CAR_LIST', response.data);
        localStorage.carData = JSON.stringify(response.data);
      }).catch(() =>{
        commit('SET_CAR_LIST', JSON.parse(localStorage.carData));
      });
    },
  },
  getters:{
    user: state => state.user,
    authenticated: state => state.user !== null,
    loader: state => state.loader,
    carData: state => state.carList
  },
  modules: {
  }
})
