import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import middleware from './middleware'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: middleware.user
  },
  {
    path: '/addCar',
    name: 'AddCar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddCar.vue'),
    beforeEnter: middleware.user
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    beforeEnter: middleware.guest
  },
  {
    path: '*',
    name: 'all',
    component: Home,
    beforeEnter: middleware.user
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('showLoader');
  next()
})

const DEFAULT_TITLE = 'Car Fleet';

router.afterEach((to) => {
  setTimeout(() => store.dispatch('hideLoader'), 1500);
  
    Vue.nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE;
    });
})


export default router
