<template>
  <div id="app">
    <navbar-component></navbar-component>
    <router-view/>
    <transition name="fade">
      <Loader v-show="this.loader"/>
    </transition>
  </div>
</template>

<script>
import NavbarComponent from './components/Navbar.vue'
import Loader from './components/Modals/LoaderModal.vue'
import { mapGetters } from 'vuex'

export default {
  components:{
    NavbarComponent,
    Loader
  },
  computed:{
    ...mapGetters({
      loader: 'loader'
    })
  }
  
}
</script>

<style>
body{
  margin: 0;
  background: rgb(56, 56, 56);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
.error_message{
  font-weight: 600;
  color: #a53232;
  font-size: 15px;
}
.container{
    width: 90%;
    margin: 30px auto;
    max-width: 1300px;
    padding: 20px 5px 20px 5px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
