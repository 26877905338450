<template>
    <div class="modal_backdrop">

        <div class="modal_delete">
            
            <div class="modal_delete_container">

                <div @click="closeDeleteModal" class="modal_delete_close">
                    X
                </div>

                <div class="modal_delete_header">
                    Czy na pewno chcesz usunąć?
                </div>

                <div class="modal_delete_inside">
                    
                    <div class="modal_delete_buttons">

                        <button @click="closeDeleteModal" class="delete_button">Anuluj</button>

                        <button @click="deleteElement" class="delete_button red">Usuń</button>

                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>

export default {
  name: 'DeleteModal',
  methods:{
      closeDeleteModal(){
          this.$emit('closeModal');
      },
      deleteElement(){
          this.$emit('deleteCar');
      }
  }
}
</script>

<style scoped>
.modal_backdrop{
    height: 100vh;
    width: 100vw;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
}
.modal_delete{
    width: 90%;
    max-width: 400px;
    background: #363636;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 0px 1px 6px #383838;
}
.modal_delete_container{
    width: 100%;
    height: 100%;
    position: relative
}
.modal_delete_buttons{
    width: 90%;
    margin: auto;
}
.delete_button{
    width: 115px;
    height: 35px;
    border-radius: 5px;
    border: none;
    margin: 5px;
    cursor: pointer;
}
.red{
    background: rgb(88, 19, 19);
    color: white;
    box-shadow: 0 0 4px black;
}
.modal_delete_header{
    width: 100%;
    color: white;
    padding: 30px 2px 10px 2px;
}
.modal_delete_inside{
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    padding: 20px 0 30px 0;
}
.modal_delete_close{
    position: absolute;
    top: -20px;
    right: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #9e9e9e;
    line-height: 40px;
    cursor: pointer;
    color: black;
    font-weight: 700;
}
.delete_button:hover, .modal_delete_close:hover{
    transform: scale(1.05);
    transition: .4s;
}
</style>