<template>
    <div v-if="!isLoginPage" id="nav">

        <div class="logo">
            <router-link to="/"><img src="@/assets/logo.png"/></router-link>
        </div>
        <!--<router-link v-if="authenticated" to="/">Home |</router-link> 
        <router-link v-if="authenticated" to="/about"> About </router-link> 
        <router-link v-if="!authenticated" to="/login">| Login</router-link> -->

        <button v-if="authenticated" class="auth_button" @click="logout">
            <power-icon size="1.5x" class="logout_icon"></power-icon>
        </button>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import {PowerIcon} from '../assets/Icons/Icons'

export default {
  name: 'NavbarComponent',
  components:{
    PowerIcon
  },
  data(){
    return{
      loading: false,
      error: false,
      isLoginPage: false
    }
  },
  methods:{
    async logout(){
      this.loading = true;

      try{
        await this.$store.dispatch('logout');
        await this.$router.push({name: 'Login'});
        this.isLoginPage = true;

      }catch(error){
        this.error = error;

      }finally{
        this.loading = false;

      }

    },
    checkIfLoginPage(){
      if(window.location.pathname === '/login'){
          this.isLoginPage = true;
      }else{
          this.isLoginPage = false;
      }
    }
  },
  mounted(){
      this.checkIfLoginPage();
  },
  computed:{
    ...mapGetters({
      authenticated: 'authenticated'
    })
  }
}
</script>

<style scoped>
#nav{
    height: 70px;
    box-shadow: 0px 2px 8px rgb(31 31 31);
    padding: 0;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100vw;
    z-index: 10;
    top: 0;
    background: #383838;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.auth_button{
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 2px solid #b7b7b7;
    cursor: pointer;
    margin: 13px 20px auto auto;
    font-size: 12px;
    font-weight: 600;
    line-height: 35px;
    position: absolute;
    padding-top: 8px;
    right: 0;
    background: #585858;
}
.auth_button:hover{
    transform: scale(1.1);
    transition: .4s;
}
.logout_icon{
    color: #d3d3d3;
    stroke-width: 3px;
}
.logo{
    height: 60px;
    width: 120px;
    margin-top: 5px;
}
.logo img{
    width: 100%;
    border-radius: 20px;
}
</style>