<template>
    <div class="container">
        <div class="add_new">
            <router-link to="/addCar" tag="button" class="addNew_btn">Nowy pojazd</router-link>
        </div>
        <div v-for="(item, index) in CarData" :key="item.id" class="grid_record">
            <div class="grid_column_count">{{index+1}}</div>
            <div class="grid_column">{{item.CarMake}} <label class="grid_column_label">Marka</label></div>
            <div class="grid_column">{{item.Model}} <label class="grid_column_label">Model</label></div>
            <div class="grid_column">{{item.Year}} <label class="grid_column_label">Rok prod.</label></div>
            <div class="grid_column">{{item.CarPlate}} <label class="grid_column_label">Nr Rejestracyjny</label></div>
            <div class="grid_column">{{item.CarReviewDateTo}}<label class="grid_column_label">Następny przegląd</label></div>
            <div class="grid_column">{{item.InsuranceTo}} <label class="grid_column_label">OC do</label></div>
            <div class="grid_column">{{item.OilChangeDate}} <label class="grid_column_label">Poprzednia wym. oleju</label></div>
            <div class="grid_column actions">
                <div @click="updateElement(item.id)" class="grid_column_action_button">
                    <EditIcon size="1.2x" class="custom-class"></EditIcon>
                </div>
                <div @click="deleteElement(item.id)" class="grid_column_action_button red">
                     <Trash2Icon size="1.2x" class="custom-class"></Trash2Icon>
                </div>            
            </div>
        </div>
        <DeleteModal v-if="showDeleteModal"  @closeModal="CloseModal" @deleteCar="DeleteModalCar"/>
        <UpdateModal v-if="showUpdateModal" @closeModal="CloseModal" :CarData="UpdateCarData"/>
    </div>
</template>
<script>
import {Trash2Icon , EditIcon} from '../assets/Icons/Icons'
import DeleteModal from '../components/Modals/DeleteModal.vue'
import UpdateModal from '../components/Modals/UpdateModal.vue'
import API from '@/api/repository.js'

export default{
    name: 'CarGrid',
    props:{
        CarData: []
    },
    components:{
        Trash2Icon,
        EditIcon,
        DeleteModal,
        UpdateModal
    },
    data(){
        return{
            showDeleteModal: false,
            itemToDelete: null,
            showUpdateModal: false,
            itemToUpdate: null,
            UpdateCarData: []
        }
    },
    methods:{
        deleteElement(id){
            this.showDeleteModal = true;
            this.itemToDelete = id;
            document.querySelector('html').style.overflow = 'hidden';
        },
        CloseModal(){
            this.showDeleteModal = false;
            this.showUpdateModal = false;
            this.itemToDelete = null;
            this.itemToUpdate = null;
            document.querySelector('html').style.overflow = 'auto';
        },
        async updateElement(item){
            try{
                const {data} = await API.getCar(item);
                this.UpdateCarData = data;
                console.log(this.UpdateCarData);
                this.showUpdateModal = true;
                document.querySelector('html').style.overflow = 'hidden';

            }catch(error){
                 alert(error.response.data);
            }

        },
        async DeleteModalCar(){
            try{
                await API.deleteCar(this.itemToDelete);

            }catch(error){
                alert(error.response.data);
            }finally{
                this.showDeleteModal = false;
                this.itemToDelete = null;
                window.location.reload();
            }
        }
    },
    mounted(){
       // var dateObject = new Date(this.CarData[0].InsuranceTo);
        //var dateObject2 = new Date(this.CarData[0].CarReviewDateTo);
        //var milliseconds = dateObject.getMilliseconds();
        //console.log(dateObject-dateObject2);
    }
}
</script>
<style scoped>
.grid_record{
    width: calc(100% -20px);
    min-height: 50px;
    display:-webkit-box;
    flex-wrap: wrap;
    box-shadow: 0 2px 8px rgb(22, 22, 22);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    background: rgb(53, 53, 53);
}
.grid_column_count, .grid_column{
    margin: 5px 2% .5% .2%;
    height: 50px;
    line-height: 65px;
}
.grid_column{
    width: 13%;
    min-width: 100px;
    position: relative;
    background: rgb(66, 66, 66);
    padding-bottom: 7px;
    border-radius: 5px;
    font-size: 14.5px;
    font-weight: 600;
    color: rgb(207, 207, 207);
}
.grid_column_count{
    width: 50px;
    border-radius: 50%;
    margin: 5px 30px 20px .2%;
    background: rgb(69, 84, 87);
    color: white;
    line-height: 50px;
    font-weight: 700;
}
@media(max-width:550px){
    .grid_column{
        width: 100%;
        line-height: 55px;
    }
    .grid_column_count{
        position: absolute;
        top: -30px;
        left: -10px;
        z-index: 0;
    }
    .grid_record{
        margin-bottom: 35px;
    }
}
.add_new{
    margin-bottom: 20px;
}
.addNew_btn{
    width: 200px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 10px;
    font-weight: 600;
    border: none;
    box-shadow: 0 0 4px 2px rgb(46, 46, 46);
    cursor: pointer;
    background: rgb(69, 84, 87);
    color: white;
}
.grid_column_label{
    position:absolute;
    top: 8px;
    left: 5px;
    transform: translate(0, -50%);
    font-size: 11px;
    line-height: 10px;
    text-align: left;
    font-weight: 500;
    color: white;
}
.grid_column.actions{
    display: flex;
    justify-content: center;
}
.grid_column_action_button{
    border-radius: 50%;
    background: rgb(61, 75, 80);
    margin: 8px 3% auto 3%;
    height: 40px;
    width: 40px;
    box-shadow: 0 0 4px rgb(43, 43, 43);
}
.grid_column_action_button.red{
    background: rgb(88, 19, 19);
}
.custom-class{
    padding-bottom: 9px;
    cursor: pointer;
    color: white;
}
.custom-class:hover{
    transform: scale(1.1);
}
@media(max-width:550px){
    .grid_column_action_button{
        margin: 3.5px 10px auto 10px;
        height: 50px;
        width: 50px;
    }
    .custom-class{
        padding-bottom: 0;
        transform: scale(1.2);
    }
    .custom-class:hover{
        transform: scale(1.3);
    }
    .addNew_btn{
        width: 100%;
    }
}
</style>